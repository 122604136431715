import React from 'react';
import { graphql, HeadProps, navigate } from 'gatsby';

const NotFoundPage = () => {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) return navigate('/not-found');

  return null;
};

export default NotFoundPage;

export function Head(props: HeadProps) {
  return <>
    <title>404 | Safebrok</title>
    <meta name='robots' content='noindex, nofollow' />
  </>;
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
